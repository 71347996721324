/* eslint-disable class-methods-use-this */
import { Turbo } from '@hotwired/turbo-rails'
import Controller from '~/controllers/application_controller'

// Source https://github.com/hotwired/turbo/issues/37
export default class extends Controller {
  connect() {
    this.boundFetchRequested = this.#fetchRequested.bind(this)
    this.boundDisableTurboScroll = this.#disableTurboScroll.bind(this)
    this.boundPageRendered = this.#pageRendered.bind(this)
  }

  disconnect() {
    this.#removeListeners()
  }

  trigger() {
    this.listenerSet = true
    document.addEventListener('turbo:visit', this.boundFetchRequested)
    document.addEventListener(
      'turbo:before-render',
      this.boundDisableTurboScroll
    )
    document.addEventListener('turbo:render', this.boundPageRendered)
  }

  #fetchRequested() {
    window.paneStartPageScrollY = window.scrollY
  }

  #disableTurboScroll() {
    Turbo.navigator.currentVisit.scrolled = true
  }

  #pageRendered() {
    if (window.paneStartPageScrollY != null)
      window.scrollTo(0, window.paneStartPageScrollY)

    this.#removeListeners()
  }

  #removeListeners() {
    document.removeEventListener('turbo:visit', this.boundFetchRequested)
    document.removeEventListener(
      'turbo:before-render',
      this.boundDisableTurboScroll
    )
    document.removeEventListener('turbo:render', this.boundPageRendered)
  }
}
