import Controller from '~/controllers/application_controller'

// Connects to data-controller="redirect"
export default class extends Controller {
  static values = {
    add: String,
    target: String,
  }

  connect() {
    const element = document.getElementById(this.targetValue)
    element.classList.add(this.addValue)
  }
}
