import Controller from '~/controllers/application_controller'

export default class extends Controller {
  static targets = [
    'blockThresh',
    'blockedState',
    'priceList',
    'errorText',
    'availabilitySync',
    'priceSync',
  ]

  submit() {
    const syncAvailbility = this.availabilitySyncTarget.value === 'true'
    const syncPrice = this.priceSyncTarget.value === 'true'

    if (!syncAvailbility && !syncPrice) {
      this.#setError('Debes activar alguna opción')
      return
    }

    if (syncAvailbility) {
      if (parseInt(this.blockThreshTarget.value, 10) < 0) {
        this.#setError('Ingresa un umbral de bloqueo válido')
        return
      }

      if (this.blockedStateTarget.value === '') {
        this.#setError('Ingresa un estado al bloquearse')
        return
      }
    }
    if (syncPrice && this.priceListTarget.value === '') {
      this.#setError('Ingresa una lista de precios')
      return
    }

    this.element.requestSubmit()
  }

  #setError(error) {
    this.errorTextTarget.textContent = error
  }
}
