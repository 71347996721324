import { registerControllers } from 'stimulus-vite-helpers'
import application from './application'

const controllersRaw = import.meta.globEager('../**/*controller.js')

// Fix for accessing controllers inside folders
const controllers = {}
Object.entries(controllersRaw).forEach(([key, value]) => {
  // eslint-disable-next-line prefer-const
  let [dir, name] = key.split('/').slice(-2)
  if (name === 'controller.js') name = `${dir}_controller.js`
  controllers[`./${name}`] = value
})

registerControllers(application, controllers)
