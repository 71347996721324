import Controller from '~/controllers/application_controller'

export default class extends Controller {
  static targets = ['form']

  search() {
    clearTimeout(this.timeout)
    this.boundTimeout = setTimeout(() => {
      this.formTarget.requestSubmit()
    }, 1500)
  }
}
