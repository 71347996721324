import Controller from '~/controllers/application_controller'

export default class extends Controller {
  static targets = ['showActive', 'hideActive']

  static values = {
    active: { type: Boolean, default: false },
  }

  connect() {
    this.active = this.activeValue
    this.active = !this.active
    this.toggle({ detail: null, params: null })
  }

  toggle({ detail, params }) {
    if (params?.stateEventKey != null) {
      this.active = detail[params.stateEventKey]
    } else {
      this.active = !this.active
    }

    this.showActiveTargets.forEach((element) => {
      if (this.active) element.classList.remove('hidden')
      else element.classList.add('hidden')
    })

    this.hideActiveTargets.forEach((element) => {
      if (this.active) element.classList.add('hidden')
      else element.classList.remove('hidden')
    })
  }
}
