/* eslint-disable class-methods-use-this */
import Controller from '~/controllers/application_controller'

export default class extends Controller {
  static targets = ['animate']

  spin(e) {
    this.#applyAnimation(e, 'animate-spin')
  }

  #applyAnimation(evt, animation) {
    const targets = this.animateTargets.length
      ? this.animateTargets
      : [this.#getNode(evt.target)]

    const callerType = this.#getNode(evt.target).nodeName

    if (callerType === 'A') {
      document.addEventListener('turbo:before-fetch-request', () => {
        targets.forEach((el) => el.classList.add(animation))
      })
      return
    }
    targets.forEach((el) => el.classList.add(animation))
  }

  // #hasClass(element, _class) {
  //   return element.classList.contains(_class)
  // }

  #getNode(element) {
    return element.closest('[data-action]')
  }
}
