import Controller from '~/controllers/application_controller'

export default class extends Controller {
  static targets = ['optionList', 'selectedText']

  static values = {
    selectedId: String,
  }

  connect() {
    this.open = false
    this.selectedId = this.selectedIdValue
    this.boundClose = this.#close.bind(this)
  }

  disconnect() {
    if (this.open) this.#close()
  }

  toggle() {
    if (this.open) {
      this.#close()
      return
    }
    this.#open()
  }

  select({ target, params }) {
    if (params.id === this.selectedId) return

    this.selectedTextTarget.textContent = target.textContent
    this.selectedId = params.id
    const event = new CustomEvent('change', { detail: { id: this.selectedId } })
    this.element.dispatchEvent(event)
  }

  #open() {
    this.optionListTarget.classList.remove('hidden')
    document.addEventListener('click', this.boundClose)
    this.open = true
  }

  #close(e) {
    if (e && this.element.contains(e.target)) return
    this.optionListTarget.classList.add('hidden')
    document.removeEventListener('click', this.boundClose)
    this.open = false
  }
}
