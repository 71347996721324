import Controller from '~/controllers/application_controller'

// Connects to data-controller="redirect"
export default class extends Controller {
  static values = {
    url: String,
    params: Object,
  }

  connect() {
    console.log(this.urlValue)
  }

  get() {
    fetch(this.urlValue)
  }

  post() {
    fetch(this.urlValue, {
      method: 'POST',
      body: JSON.stringify(this.paramsValue),
      headers: { 'Content-Type': 'application/json' },
    })
  }
}
