import Controller from '~/controllers/application_controller'

export default class extends Controller {
  static targets = ['divider']

  connect() {
    this.boundHandleScroll = this.#handleScroll.bind(this)
    document.addEventListener('scroll', this.boundHandleScroll)
  }

  disconnect() {
    document.removeEventListener('scroll', this.boundHandleScroll)
  }

  #handleScroll(event) {
    const scroll =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0

    if (scroll > this.element.offsetHeight) {
      this.dividerTarget.classList.add('opacity-0')
      this.element.classList.add('shadow-xl')
    } else {
      this.dividerTarget.classList.remove('opacity-0')
      this.element.classList.remove('shadow-xl')
    }
  }
}
