// eslint-disable-next-line import/no-extraneous-dependencies
import LogRocket from 'logrocket'
import Controller from '~/controllers/application_controller'

// Connects to data-controller="redirect"
export default class extends Controller {
  static values = {
    deployEnv: String,
    appId: String,
    user: Object,
    release: String,
  }

  connect() {
    if (this.#shoudInitLogRocket()) {
      this.#initLogRocket()
      if (this.userValue?.id) this.#identifyUser()
    } else if (this.#shoudReIdentifyUser()) {
      this.#identifyUser()
    }
  }

  #shoudInitLogRocket() {
    const path = window.location.pathname

    return (
      !path.includes('/admin') &&
      this.deployEnvValue === 'production' &&
      !this.#logRocketStarted()
    )
  }

  #shoudReIdentifyUser() {
    return (
      this.#logRocketStarted() &&
      this.userValue?.id !== window.currentUser?.id &&
      this.userValue?.id
    )
  }

  // eslint-disable-next-line class-methods-use-this
  #logRocketStarted() {
    // eslint-disable-next-line no-underscore-dangle
    return LogRocket._isInitialized
  }

  #initLogRocket() {
    LogRocket.init(this.appIdValue, {
      release: this.envValue,
      env: this.envValue,
    })
  }

  #identifyUser() {
    window.currentUser = this.userValue
    LogRocket.identify(this.userValue.id, {
      name: this.userValue.name,
      email: this.userValue.email,
    })
  }
}
