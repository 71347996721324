import Controller from '~/controllers/application_controller'

export default class extends Controller {
  static targets = ['input', 'errorText']

  submit(event) {
    const storeIds = this.inputTargets.map((el) => el.getAttribute('value'))
    event.preventDefault()
    const notSet = storeIds.some((el) => el == null)

    if (notSet) {
      this.errorTextTarget.textContent =
        'Selecciona una opción para todas las sucursales'
      return
    }

    const assigned = storeIds.filter((el) => el !== '-1')
    const repeated = assigned.length !== new Set(assigned).size
    if (repeated) {
      this.errorTextTarget.textContent = 'No pueden haber sucursales repetidas'
      return
    }

    this.element.requestSubmit()
  }
}
