import Controller from '~/controllers/application_controller'

export default class extends Controller {
  static targets = ['toggleButton', 'circle']

  static values = {
    active: { type: Boolean, default: false },
    enabled: { type: Boolean, default: true },
    activeClasses: String,
    inactiveClasses: String,
    moveClass: String,
  }

  connect() {
    this.active = this.activeValue
    this.active = !this.active
    this.#performSwitch()
  }

  toggle() {
    if (!this.enabledValue) return
    this.#performSwitch()
    this.publishEvent('change', { active: this.active })
  }

  #performSwitch() {
    this.active = !this.active

    if (this.active) {
      this.toggleButtonTarget.classList.add(this.activeClassesValue.split(' '))
      this.toggleButtonTarget.classList.remove(
        this.inactiveClassesValue.split(' ')
      )
      this.circleTarget.classList.add(this.moveClassValue.split(' '))
    } else {
      this.toggleButtonTarget.classList.remove(
        this.activeClassesValue.split(' ')
      )
      this.toggleButtonTarget.classList.add(
        this.inactiveClassesValue.split(' ')
      )
      this.circleTarget.classList.remove(this.moveClassValue.split(' '))
    }
  }
}
